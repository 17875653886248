/* line 1, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.marquee[data-v-da55aa98] {
    width: 100%;
    overflow: hidden;
    border: 0px solid #ccc;
    background: #6E8FC9;
    color: #fff;
    padding: 0px 1px;
    margin-right: 15%;
    margin-bottom: 13px;
}

/* line 12, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
ul.marquee li[data-v-da55aa98] {
    display: inline-block;
    padding: 5px 10px 1px;
    font-size: 16px;
    font-weight: 600;
    font-family: 'open-sans-regular' !important;
}

/* line 20, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.bet_status_box[data-v-da55aa98] {
    min-height: 100%;
    height: 100%;
    background-color: #3A61A2;
    border-right: solid 10px #eee;
    padding: 0px;
}

/* line 28, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.favorite_market span[data-v-da55aa98] {
    color: #fff !important;
}

/* line 32, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.center>th[data-v-da55aa98] {
    text-align: center;
}

/* line 34, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.input-coin[data-v-da55aa98] {
    width: 100%;
    text-align: center;
}

/* line 39, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.cursor-pointer[data-v-da55aa98] {
    cursor: pointer;
}

/* line 43, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.close-status[data-v-da55aa98] {
    background-color: #fff;
    opacity: 1.5;
}

/* line 48, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.event[data-v-da55aa98] {
    font-size: 28px;
    text-align: center;
    min-height: 50px;
    max-height: 50px;
    line-height: 50px;
    color: white;
    font-family: 'open-sans-regular' !important;
    margin-bottom: 0px;
    overflow: hidden;
}

/* line 60, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.event_bet_status[data-v-da55aa98] {
    font-size: 18px;
    text-align: center;
    min-height: 40px;
    max-height: 40px;
    line-height: 40px;
    color: white;
    font-family: 'open-sans-regular' !important;
    /*font-family: 'open-sans-bold' !important;
    /*font-family: 'open-sans-extrabold' !important;*/
    margin-bottom: 0px;
}

/* line 73, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.refresh_img[data-v-da55aa98] {
    position: relative;
    top: 14px;
    font-size: 35px !important;
    color: #fff;
}
@media (max-width: 768px) {

    /* line 81, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.event[data-v-da55aa98] {
        font-size: 20px;
}

    /* line 85, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.event_bet_status[data-v-da55aa98] {
        font-size: 16px;
}
}
@media (max-width: 767px) {

    /* line 91, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.mob-col-width-ch[data-v-da55aa98] {
        width: 40%;
}
}

/* line 96, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.span-btn[data-v-da55aa98] {
    height: 35px;
    width: auto;
    margin-left: 0px;
}

/* line 102, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.img-height[data-v-da55aa98] {
    height: 30px;
    margin-top: 5px;
    width: auto;
    margin-left: 3px;
}
@media all and (max-width: 767px) {

    /* line 110, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.refresh-btn[data-v-da55aa98] {
        display: none !important;
}
}
@media all and (max-width: 768px) {

    /* line 116, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.span-btn[data-v-da55aa98] {
        height: 35px;
        width: auto;
        margin-left: 0px;
}

    /* line 122, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.img-height[data-v-da55aa98] {
        height: 30px;
        margin-top: 5px;
        width: auto;
        margin-left: 0px;
}

    /* line 129, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.match-box[data-v-da55aa98] {
        margin-top: -4% !important;
}

    /* line 133, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.navbar[data-v-da55aa98] {
        margin-bottom: 0px !important;
}

    /* line 137, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.header-top-spacing[data-v-da55aa98] {
        padding-top: 10px;
}

    /* line 141, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.flag[data-v-da55aa98] {
        padding: 8px 8px 8px 8px !important;
}

    /* line 145, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.inplay-show-tbl-td img[data-v-da55aa98] {
        position: absolute;
        right: 24px;
        top: 18px;
}

    /* line 151, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.refresh-small-screen[data-v-da55aa98] {
        text-align: center;
        background-color: #fff;
        padding-top: 15px;
        padding-bottom: 14px;
        width: 15.7%;
}
}
@media all and (min-width: 768px) {

    /* line 161, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.inplay-show-tbl-td img[data-v-da55aa98] {
        display: none;
}

    /* line 165, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.bet-now[data-v-da55aa98] {
        position: relative;
        right: 15px;
}

    /* line 170, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.refresh-small-screen[data-v-da55aa98] {
        display: none;
}
}

/* line 175, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.refresh-btn[data-v-da55aa98] {
    padding-right: 15px;
    /*width: 14.3%;*/
    float: right;
    background-color: #fff;
    text-align: center;
    padding: 14px;
    position: relative;
    right: 5px;
    min-height: 100%;
    height: 100%;
    background-color: #3a61a2;
}
@media all and (max-width: 480px) and (min-width: 300px) {

    /* line 191, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.match-score[data-v-da55aa98] {
        width: 75% !important;
}

    /* line 195, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.refresh-small-screen[data-v-da55aa98] {
        width: 23.5% !important;
        padding-top: 18px !important;
        padding-bottom: 17px !important;
}

    /* line 201, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.refresh-small-screen img[data-v-da55aa98] {
        width: 40px !important;
}
}
@media all and (max-width: 644px) {

    /* line 207, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.event_bet_status[data-v-da55aa98] {
        font-size: 11px;
        /*text-align:left !important;*/
        font-weight: 600 !important;
}

    /* line 213, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.event[data-v-da55aa98] {
        font-size: 16px;
        /*    letter-spacing: 0px !important;
          text-align: left !important;
          font-weight: 600 !important;*/
}

    /* line 220, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.bet_status_box[data-v-da55aa98] {
        border-right: solid 0px #eee !important;
}
}
@media all and (max-width: 479px) {

    /* line 227, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.event_bet_status[data-v-da55aa98] {
        font-size: 10px;
        /*text-align:left !important;*/
        font-weight: 600 !important;
}

    /*.event {
        font-size: 28px;
        letter-spacing: 0px !important;
        text-align: left !important;
        font-weight: 600 !important;
    }*/
}
@media all and (max-width: 768px) {

    /* line 248, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.inplay-show-tbl-td[data-v-da55aa98] {
        text-align: center !important;
}

    /*     .event{
        font-size: 28px;
        text-align:center;
        min-height:45px;
        max-height: 45px;
        color: white;
        padding-top : 5px;
      }*/
}
@media all and (min-width: 768px) {

    /* line 263, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
}

/* line 268, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.navbar-fixed-bottom[data-v-da55aa98] {
    position: static !important;
}

/* line 272, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
body[data-v-da55aa98] {
    overflow: auto;
}

/* line 280, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.inplay-show-tbl-td[data-v-da55aa98] {
    text-align: center !important;
}

/* line 284, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.session_count_right[data-v-da55aa98] {
    position: absolute;
    text-align: right;
    width: 34%;
}
@media all and (min-width: 1024px) and (max-width: 1200px) {

    /* line 291, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.session_count_right[data-v-da55aa98] {
        width: 40%;
}
}
@media all and (min-width: 1600px) {

    /* line 297, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.session_count_right[data-v-da55aa98] {
        width: 27.2%;
}
}

/* line 302, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.content_matches_wrapper[data-v-da55aa98] {
    max-height: 100px;
    height: 100px;
    padding: 5px;
    overflow: hidden;
}

/* line 309, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.content_in_play_wrapper[data-v-da55aa98] {
    min-height: 100%;
    height: 100%;
    background-color: #3A61A2;
    overflow: hidden;
    border-right: solid 5px #eee;
}
@media all and (max-width: 480px) {

    /* line 318, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.event[data-v-da55aa98] {
        line-height: 20px;
}
}

/* line 323, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.first-runner-bet-odds-yes-value[data-v-da55aa98] {
    font-family: 'open-sans-extrabold' !important;
    padding: 7px !important;
    font-size: 16px;
}

/* line 329, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.first-runner-bet-odds-no-value[data-v-da55aa98] {
    font-family: 'open-sans-extrabold' !important;
    padding: 7px !important;
    font-size: 16px;
}

/* line 337, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.bet_status_box[data-v-da55aa98],
.match-status-table[data-v-da55aa98],
.refresh-btn.reload-class[data-v-da55aa98] {
    height: 100px !important;
}

/* line 341, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.match-status-table[data-v-da55aa98] {
    display: table !important;
    line-height: 1.5;
}

/* line 345, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.match-status-text[data-v-da55aa98] {
    display: table-cell !important;
    text-align: center !important;
    vertical-align: middle !important;
    padding: 0px 10px !important;
}

/* line 351, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.bet-status-text-div[data-v-da55aa98] {
    display: table !important;
    line-height: 1;
}

/* line 355, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.bet-status-text-p[data-v-da55aa98] {
    display: table-cell !important;
    text-align: center !important;
    vertical-align: middle !important;
    font-size: 20px;
    font-weight: 600;
    color: white;
    font-family: 'open-sans-extrabold' !important;
}

/* line 364, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.bet-status-text-p i[data-v-da55aa98] {
    font-size: 15px;
    position: relative;
    bottom: 10px;
}
@media all and (max-width: 1200px) {

    /* line 370, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.bet-status-text-p i[data-v-da55aa98] {
        font-size: 14px;
        position: relative;
        bottom: 5px;
}
}
@media all and (max-width: 800px) {

    /* line 377, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.bet-status-text-p i[data-v-da55aa98] {
        position: relative;
        bottom: 5px;
}
}
@media all and (max-width: 600px) {

    /* line 383, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.bet-status-text-p i[data-v-da55aa98] {
        font-size: 12px;
        position: relative;
        bottom: 2px;
}
}
@media all and (max-width: 420px) {

    /* line 390, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.bet-status-text-p i[data-v-da55aa98] {
        font-size: 9px;
        position: relative;
        bottom: 2px;
}
}

/* line 396, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.single-no-text[data-v-da55aa98] {
    font-size: 50px;
    font-family: 'open-sans-extrabold' !important;
}

/*LOAD BUTTON CSS FROM USER MATCH SHOW PAGE*/
/* line 404, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.button.alert[data-v-da55aa98]:before,
.button.alert[data-v-da55aa98]:after {
    box-sizing: border-box;
}

/* line 410, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.button.round[data-v-da55aa98],
.button.round[data-v-da55aa98] {
    border-radius: 1000px;
}

/* line 414, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.button.alert[data-v-da55aa98],
.button.alert[data-v-da55aa98] {
    background-color: #3A61A2;
    border-color: #3A61A2;
    color: white;
}
@media only screen and (min-width: 40.063em) {

    /* line 421, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.button[data-v-da55aa98],
    .button[data-v-da55aa98] {
        display: inline-block;
}
}

/* line 426, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.button[data-v-da55aa98]:not(.disabled):before {
    content: '';
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -11px;
    width: 20px;
    height: 20px;
    border: 4px solid;
    border-left-color: transparent;
    border-radius: 50%;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    transition-duration: 0.5s;
    transition-property: opacity;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-name: rotate-data-v-da55aa98;
    animation-timing-function: linear;
}

/* line 460, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.button[data-v-da55aa98]:not(.disabled):after {
    content: '';
    display: inline-block;
    height: 100%;
    width: 0px;
    transition-delay: 0.5s;
    transition-duration: 0.75s;
    transition-property: width;
}

/* line 479, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.button[data-v-da55aa98],
.button[data-v-da55aa98] {
    border-style: solid;
    border-width: 0px;
    cursor: pointer;
    font-family: "open-sans-semibold";
    font-weight: normal;
    line-height: normal;
    margin: 0 0 1.25rem;
    position: relative;
    text-decoration: none;
    text-align: center;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    display: inline-block;
    padding-top: 1rem;
    padding-right: 2rem;
    padding-bottom: 1.0625rem;
    padding-left: 2rem;
    font-size: 14px;
    background-color: #008cba;
    border-color: #007095;
    color: white;
    transition: background-color 300ms ease-out;
}
@media all and (max-width: 767px) {

    /* line 504, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.button.alert[data-v-da55aa98] {
        font-size: 12px;
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
}
}

/* line 511, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.button:not(.disabled).sending[data-v-da55aa98] {
    pointer-events: none;
    cursor: not-allowed;
}

/* line 515, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.button:not(.disabled).sending[data-v-da55aa98]:not(.expand) {
    text-align: left;
}

/* line 518, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.button:not(.disabled).sending[data-v-da55aa98]:before {
    transition-delay: 0.5s;
    transition-duration: 1s;
    filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
}

/* line 530, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.button:not(.disabled).sending[data-v-da55aa98]:after {
    transition-delay: 0s;
    width: 20px;
}
@keyframes rotate-data-v-da55aa98 {

    /* line 539, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
0% {
        transform: rotate(0deg);
}

    /* line 545, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
100% {
        transform: rotate(360deg);
}
}

/*LOAD BUTTON CSS FROM USER MATCH SHOW PAGE*/
/* line 555, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.button.alert.round[data-v-da55aa98] {
    margin-left: 15px;
}
@media all and (max-width: 767px) {

    /* line 560, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.place-amount.load-button-new-class[data-v-da55aa98] {
        width: 100%;
        text-align: left;
}
}
@media all and (min-width: 768px) {

    /* line 567, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.place-amount.load-button-new-class[data-v-da55aa98] {
        width: 50%;
        float: right;
        text-align: right;
}

    /* line 573, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.market-block[data-v-da55aa98],
    .session-block[data-v-da55aa98] {
        width: 50%;
        float: left;
        text-align: left;
        line-height: 3.2;
}
}

/* line 582, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.market-block[data-v-da55aa98],
.session-block[data-v-da55aa98],
.place-amount-div[data-v-da55aa98] {
    height: 98px;
    line-height: 95px;
}

/* line 587, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.place-amount-div input[data-v-da55aa98] {
    height: 25px;
}

/* BET VALUES (500, 1000, CLEAR) STYLE */
/* line 592, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
table.bet-value-buttons-table[data-v-da55aa98] {
    text-align: center;
    margin-top: 10px;
    /*margin-left: 50px; */
}

/* line 598, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
table.bet-value-buttons-table tr td button[data-v-da55aa98] {
    width: 60px;
    padding: 10px 0;
    margin: 0;
    text-align: center;
    font-size: 14px;
    border-radius: 50px;
    border: none;
    outline: none;
    background-color: #61ACDE;
    color: #fff;
}

/* line 611, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
table.bet-value-buttons-table tr td[data-v-da55aa98] {
    padding-right: 5px;
    padding-bottom: 5px;
}

/* line 616, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
table.bet-value-buttons-table tr td button.bet-value-clear-button[data-v-da55aa98] {
    background-color: #d45151;
    color: #fff;
}
@media all and (max-width: 768px) {

    /* line 622, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
table.bet-value-buttons-table[data-v-da55aa98] {
        margin-top: 7px;
}

    /* line 625, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
table.bet-value-buttons-table tr td button[data-v-da55aa98] {
        width: 43px;
        padding: 8px 0px;
        margin: 0;
        text-align: center;
        font-size: 12px;
        border-radius: 1000px;
}

    /* line 634, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
#coin_countity_bet[data-v-da55aa98],
    #coin_countity_session[data-v-da55aa98] {
        width: 100px;
}

    /* line 637, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
table.bet-value-buttons-table[data-v-da55aa98] {
        margin-left: 0px;
}

    /* line 640, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.place-amount-div[data-v-da55aa98] {
        padding-bottom: 0px;
}

    /* line 643, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.place-bet-buttons-div[data-v-da55aa98] {
        padding: 0;
}

    /* line 646, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
table.bet-value-buttons-table tr td[data-v-da55aa98] {
        padding-right: 8px;
}
}
@media all and (max-width: 480px) {

    /* line 651, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
table.bet-value-buttons-table tr td button[data-v-da55aa98] {
        font-size: 11px;
}

    /* line 654, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.break-on-mobile[data-v-da55aa98] {
        display: block !important;
}

    /* line 657, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.align-to-right-on-mobile[data-v-da55aa98] {
        float: left;
}
}

/* line 662, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.break-on-mobile[data-v-da55aa98] {
    display: none;
}

/* BET VALUES (500, 1000, CLEAR) STYLE */
/* =========== NUM KEYBOARD STYLE =========== */
@media all and (min-width: 992px) {

    /* line 672, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.numeric-keypad-main-div[data-v-da55aa98],
    div.overlay-div[data-v-da55aa98] {
        display: none !important;
}
}
@media all and (max-width: 768px) {

    /* line 678, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.amount-and-load-button[data-v-da55aa98] {
        display: none !important;
}
}

/* line 683, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.numeric-keypad-main-div[data-v-da55aa98] {
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 2;
    display: none;
}

/* line 692, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.num-pad[data-v-da55aa98] {
    text-align: center;
    width: 100%;
    font-size: 15px;
    font-family: "open-sans-bold";
    border: 1px solid #3a61a2;
    border-collapse: collapse;
}

/* line 701, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
table.numpad-table[data-v-da55aa98] {
    background-color: rgba(233, 233, 233, 0.75);
}

/* line 705, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
table.min-max-stack-table[data-v-da55aa98] {
    border: 1px solid #3a61a2;
    color: #fff;
}

/* line 710, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
table.min-max-stack-table tr.bet-values-row td[data-v-da55aa98] {
    background-color: #3a61a2;
}

/* line 714, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
table.numpad-table td.numpad-done-button[data-v-da55aa98] {
    background-color: #3a61a2;
    color: #fff;
}

/* line 719, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
table.numpad-table td[data-v-da55aa98] {
    color: #3e3e3e;
}

/* line 722, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
table.numpad-table td input[data-v-da55aa98] {
    font-size: 15px;
}

/* line 726, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
table.min-max-stack-table td[data-v-da55aa98] {
    border: 1px solid #3a61a2;
    /*padding: 16px 0;*/
    font-size: 13px;
}

/* line 732, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
table.num-pad.input-close-table[data-v-da55aa98] {
    border: 0px solid #fff;
}

/* line 736, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
table.min-max-stack-table td input[data-v-da55aa98],
table.input-close-table td input[data-v-da55aa98] {
    width: 100%;
    text-align: center;
    outline: none;
    padding: 10px 0;
    border: none;
    background-color: rgba(255, 255, 255, 0);
}

/* line 746, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
table.input-close-table td input#quantity[data-v-da55aa98],
table.input-close-table td input#quantity-session[data-v-da55aa98] {
    color: #223f6f;
    background-color: #e9e9e9;
    font-family: "open-sans-bold";
    font-size: 16px;
    border: none;
}

/* line 754, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
table.input-close-table td.close-num-keyboard[data-v-da55aa98] {
    width: 60px;
    color: #fff;
    background-color: #631415;
    /*     background-image: url("/images/delete-button.svg"); */
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 25px;
}

/* line 763, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
table.num-pad.input-close-table td[data-v-da55aa98] {
    border: 2px solid #223f6f;
    border-bottom: 1px solid #223f6f;
}

/* line 768, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
table.min-max-stack-table td.min-stack[data-v-da55aa98] {
    background-color: #223f6f;
}

/* line 772, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
table.min-max-stack-table td.max-stack[data-v-da55aa98] {
    background-color: #3a61a2;
}

/* line 776, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
table.min-max-stack-table tr.bet-values-row td[data-v-da55aa98] {
    width: 20%;
}

/* line 780, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.num-pad tr td[data-v-da55aa98] {
    border: 1px solid #223f6f;
    border-bottom: none;
    background-color: #e9e9e9;
}

/* line 786, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.numpad-table tr td input[data-v-da55aa98] {
    width: 90%;
    padding: 10px 0;
    border: none;
    outline: none;
    background-color: rgba(255, 255, 255, 0);
}

/* line 794, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
td.clear-button[data-v-da55aa98] {
    /*     background-image: url("/images/delete.svg"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 25px auto;
}

/* line 801, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
table.done-button-table[data-v-da55aa98] {
    width: 100%;
    text-align: center;
}

/* line 806, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
table.done-button-table td.numpad-done-button[data-v-da55aa98] {
    background-color: #3b62a1;
    width: 50%;
}

/* line 811, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
table.done-button-table button.amount-btn-color[data-v-da55aa98] {
    font-size: 15px;
    padding: 10px 20px 13px 0;
    margin: 0;
    background-color: rgba(58, 97, 162, 0) !important;
}

/* line 818, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
table.done-button-table td.clear-button-td[data-v-da55aa98] {
    background-color: #631515;
}

/* line 822, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
td.clear-button-td input[data-v-da55aa98] {
    background-color: inherit;
    padding: 0;
    margin: 0;
    background-color: rgba(255, 0, 0, 0);
    border: none;
    outline: none;
    font-size: 15px;
    font-family: "open-sans-bold";
    color: #fff;
    width: 100%;
}

/* =========== NUM KEYBOARD STYLE =========== */
@media all and (max-width: 1200px) {

    /* line 838, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
table.bet-value-buttons-table tr td button[data-v-da55aa98] {
        width: 45px !important;
        font-size: 13px !important;
}
}
@media all and (max-width: 982px) {

    /* line 846, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.market-block[data-v-da55aa98],
    .session-block[data-v-da55aa98],
    .place-amount-div[data-v-da55aa98] {
        height: inherit;
        line-height: inherit;
}

    /* line 851, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.place-amount.load-button-new-class.place-amount-div[data-v-da55aa98],
    .place-amount.load-button-new-class.enter-default-value.place-bet-buttons-div[data-v-da55aa98] {
        width: 100%;
        text-align: left;
}
}

/* line 857, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
div.overlay-div[data-v-da55aa98] {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

/* line 870, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
table.market-rate-table[data-v-da55aa98] {
    width: 100%;
    background-color: #e9e9e9;
    border-collapse: collapse;
    border: 2px solid #3a61a2;
    border-bottom: 0px solid;
}

/* line 877, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
table.market-rate-table td[data-v-da55aa98] {
    width: 33%;
    text-align: center;
    font-family: "open-sans-bold";
    border: 2px solid #3a61a2;
    border-bottom: 0px solid;
    height: 40px;
    font-size: 12px;
    color: #3e3e3e;
}

/* line 887, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
table.market-rate-table td span[data-v-da55aa98] {
    color: #3a61a2;
    font-family: "open-sans-bold" !important;
}
@media all and (max-width: 480px) {

    /* line 893, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
table.market-rate-table td[data-v-da55aa98] {
        font-size: 10px;
}
}

/*STYELE FOR LANDSCAP NUMPAD*/
/* line 900, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
table.landscape-view-super-values[data-v-da55aa98] {
    width: 30%;
    text-align: center;
    font-size: 15px;
    font-family: "open-sans-bold";
    border: 1px solid #3a61a2;
    border-collapse: collapse;
}

/* line 909, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
table.landscape-view-super-values td[data-v-da55aa98] {
    border: 1px solid #223f6f;
    width: 50%;
    height: 24px;
    border-left: none;
    color: #fff;
    background-color: #3a61a2;
    font-family: "open-sans-semibold";
    font-size: 12px;
}

/* line 920, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
table.landscape-view-super-values td input[data-v-da55aa98] {
    width: 100%;
    padding: 0;
    border: none;
    outline: none;
    background-color: rgba(255, 255, 255, 0);
}

/* line 928, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.hide-table[data-v-da55aa98] {
    display: none !important;
}

/* line 932, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.show-table[data-v-da55aa98] {
    display: table !important;
}

/* line 936, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.height-thirty[data-v-da55aa98] {
    height: 30px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

/* line 942, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.height-thirty input[data-v-da55aa98] {
    padding: 0 !important;
    font-size: 13px !important;
}

/* line 946, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.height-thirty button[data-v-da55aa98] {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    font-size: 13px !important;
}

/* line 952, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.table-width[data-v-da55aa98] {
    width: 70% !important;
    float: left;
}

/* line 957, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.cls-td[data-v-da55aa98] {
    background-color: #e9e9e9;
}
@media (orientation: landscape) {

    /* line 963, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.min-max-stack-table[data-v-da55aa98] {
        display: none !important;
}

    /* line 966, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.numpad-table[data-v-da55aa98] {
        width: 70% !important;
        float: left;
}

    /* line 970, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.cls-td[data-v-da55aa98] {
        height: 30px !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
}

    /* line 975, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.cls-td input[data-v-da55aa98] {
        padding: 0 !important;
        font-size: 13px !important;
}

    /* line 979, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.cls-td button[data-v-da55aa98] {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        font-size: 13px !important;
}

    /* line 984, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
table.landscape-view-super-values[data-v-da55aa98] {
        display: table !important;
}
}
@media (orientation: portrait) {

    /* line 991, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.min-max-stack-table[data-v-da55aa98] {
        display: table !important;
}

    /* line 994, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.numpad-table[data-v-da55aa98] {
        width: 100% !important;
        float: inherit;
}

    /* line 998, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.cls-td[data-v-da55aa98] {
        height: inherit;
        padding-top: inherit;
        padding-bottom: inherit;
}

    /* line 1003, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.cls-td input[data-v-da55aa98] {
        padding: inherit;
        font-size: inherit;
}

    /* line 1007, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
.cls-td button[data-v-da55aa98] {
        padding-top: inherit;
        padding-bottom: inherit;
        font-size: inherit;
}

    /* line 1012, /home/define/Desktop/Heroic_matka_game_changes/heroic/app/assets/stylesheets/user/show18.css.scss */
table.landscape-view-super-values[data-v-da55aa98] {
        display: none !important;
}
}